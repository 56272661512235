import { API_AI_REROLL_TEXT, SOCKET_URL } from "Constants";
import { api } from "./api";
import { io } from 'socket.io-client';
import { getBySocket } from "./socket";

export const getNewTextWithAI = async (text) => {
  if (!text) {
    return '';
  }

  const socket = io(SOCKET_URL);
  const response = await getBySocket({
    emitEventName: 'ai-page-regenerate',
    resultEventName: 'response-page-regenerate',
    payload: { text },
    socket
  });
  return response.text;
}

export const getTextWithAiPrompt = async (text) => {
  if (!text) {
    return '';
  }

  const socket = io(SOCKET_URL);
  const response = await getBySocket({
    emitEventName: 'ai-single-prompt',
    resultEventName: 'response-ai-single-prompt',
    payload: { text },
    socket
  });
  return response.result.text;
}


export function generateFingerPrint() {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < 5; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  const date = new Date();
  const timestamp = date.valueOf();
  const randomString = `${timestamp}-${result}`;
  localStorage.setItem("MechFingerprint", randomString);
  return randomString;
}